"use client";

import Countup from "react-countup";
import { useInView } from "react-intersection-observer";

const HomeInfomation = () => {
  console.log("hi");
  const { ref: refPrice, inView: inViewPrice } = useInView({
    threshold: 0.8,
    triggerOnce: true,
  });

  const { ref: refSupporter, inView: inViewSupporter } = useInView({
    threshold: 0.8,
    triggerOnce: true,
  });

  const { ref: refInfo, inView: inViewInfo } = useInView({
    threshold: 0.8,
    triggerOnce: true,
  });

  return (
    <>
      <section className="pt-10">
        <div
          className="container grid items-center mx-auto text-center"
          ref={refInfo}
        >
          <div
            className={`mt-4 ${
              inViewInfo
                ? "visible opacity-100 duration-[1500ms]"
                : "invisible opacity-0 duration-[3000ms]"
            }`}
          >
            <div className="text-sm">
              <div className="text-xl my-4 font-bold">
                ベーシックインカムを
                <br />
                挑戦者のセーフティーネットに
              </div>
              私たちは挑戦者にベーシックインカムを提供し、
              <br />
              挑戦を映像化するプラットフォームを運営しています。
              <br />
            </div>
          </div>
        </div>
      </section>

      <section className="w-full py-12 md:py-24 lg:py-32 px-4">
        <div className="container grid items-start gap-6 md:grid-cols-2 mx-auto">
          <div className="space-y-4 rounded-lg border border-gray-200 bg-white p-6 shadow-sm">
            <div className="space-y-2">
              <h3 className="text-2xl">これまでのサポート金額</h3>
              <p className="text-gray-500 dark:text-gray-400">
                Support Contribution
              </p>
            </div>
            <div className="space-y-1" ref={refPrice}>
              <p className="text-3xl font-bold">
                <Countup
                  start={0}
                  end={inViewPrice ? 82776238 : 0}
                  duration={2}
                  separator=","
                />
                円
              </p>
            </div>
          </div>
          <div className="space-y-4 rounded-lg border border-gray-200 bg-white p-6 shadow-sm ">
            <div className="space-y-2">
              <h3 className="text-2xl">サポーター数</h3>
              <p className="text-gray-500 dark:text-gray-400">
                Total Supporters
              </p>
            </div>
            <div className="space-y-1" ref={refSupporter}>
              <p className="text-3xl font-bold">
                <Countup
                  start={0}
                  end={inViewSupporter ? 8122 : 0}
                  duration={2}
                  separator=","
                />
                人
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeInfomation;
